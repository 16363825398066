module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"lt","name":"Inmoodas","short_name":"Inmoodas","description":"Kurkite nuotaiką, pakelkite erdves: jūsų profesionalūs interjero dizaineriai","theme_color":"#872124","background_color":"#fff","icon":"static/images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
