// https://mycolor.space/?hex=%23872124&sub=1
// https://colornamer.robertcooper.me/

// import { darken } from "polished"

let colors = {
  white: `#fff`,
  black: `#222`,
  porpoise: `#dadada`,
  // greyDark: `#444`,
  darkWalnut: `#57423f`,
  oldBrick: `#872124`,
  gloxinia: `#6a325f`,
  satinWeave: `#f3eed9`,
  ladyAnne: `#ffe4de`,
  desertPear: `#a9ad9b`,
  aquaGlass: `#d1e7e0`,
}

colors = {
  ...colors,
  foreground: colors.black,
  background: colors.white,
  alert: colors.oldBrick,
}

export default colors
