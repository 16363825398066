/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"

import Root from "./src/components/root"

// wrapRootElement

export const wrapRootElement = ({ element }) => {
  return <Root>{element}</Root>
}

export const onRouteUpdate = () => {
  document.dispatchEvent(new Event(`gatsbyRouteUpdate`))
}
