import { css } from "styled-components"
import { rem } from "polished"

const widths = {
  xxnarrow: 640,
  xnarrow: 800,
  narrow: 1024,
  normal: 1280,
  wide: 1440,
}

const gutter = 20

const containerGutters = () => css`
  padding-left: ${rem(gutter)};
  padding-right: ${rem(gutter)};

  @media ${({ theme }) => theme.mq.mobileDown} {
    padding-left: ${rem(gutter / 2)};
    padding-right: ${rem(gutter / 2)};
  }
`

const container = (maxWidth = widths.normal, { gutters = true } = {}) => css`
  ${gutters && containerGutters()}

  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${maxWidth &&
  css`
    max-width: ${rem(
      typeof maxWidth === `string` ? widths[maxWidth] : maxWidth
    )};
  `}
`

export { container as default, containerGutters }
